<template>
  <div v-if="rating && rating !== 0">
    <div v-if="extended">
      <div class="flex flex-col lg:flex-row lg:items-center gap-10 text-black mb-10">
        <p class="font-bold text-lg md:text-xl">
          Klanten geven ons een {{ rating * 2 }}
        </p>

        <div class="flex relative h-20 w-max gap-5">
          <div
            v-for="i in 5"
            :key="i"
            class="relative"
          >
            <img
              src="/img/star-outline.svg"
              class="w-20"
              width="20"
              height="20"
              alt=""
            >
            <img
              src="/img/star.svg"
              class="w-20 absolute top-0"
              width="20"
              height="20"
              :style="{ clipPath: getStarClipPath(i) }"
              alt=""
            >
          </div>
        </div>
      </div>
      <p class="text-gray-500">
        Onze klanten zijn enthousiast! Lees
        <a
          class="underline"
          href="https://www.google.com/search?sa=X&sca_esv=744faba34f407743&rlz=1C5CHFA_enNL1035NL1035&tbm=lcl&sxsrf=AHTn8zpKq5Cv7D5W2WQ7HmvSTMYbxKLVDg:1738759072116&q=Clubee+Reviews&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxI2MjQ2NDAzMzYyMjE2MTGwsLQ038DI-IqRzzmnNCk1VSEotSwztbx4ESuaAADRkYtWPAAAAA&rldimm=2131066322434408997&hl=en-NL&ved=2ahUKEwjkpt-mxqyLAxUngP0HHaziJLQQ9fQKegQIUhAF&biw=2002&bih=1245&dpr=2#lkt=LocalPoiReviews"
          target="_blank"
          rel="noopener noreferrer"
        >
          de reviews
        </a><br>
        van andere Clubee gebruikers.
      </p>
    </div>
    <div
      v-else
      class="flex items-center max-md:text-xs gap-5 md:gap-10 text-black"
    >
      <p>
        <span class="font-bold">
          {{ rating * 2 }}
        </span>
        uit
        <a
          class="underline"
          href="https://www.google.com/search?sa=X&sca_esv=744faba34f407743&rlz=1C5CHFA_enNL1035NL1035&tbm=lcl&sxsrf=AHTn8zpKq5Cv7D5W2WQ7HmvSTMYbxKLVDg:1738759072116&q=Clubee+Reviews&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxI2MjQ2NDAzMzYyMjE2MTGwsLQ038DI-IqRzzmnNCk1VSEotSwztbx4ESuaAADRkYtWPAAAAA&rldimm=2131066322434408997&hl=en-NL&ved=2ahUKEwjkpt-mxqyLAxUngP0HHaziJLQQ9fQKegQIUhAF&biw=2002&bih=1245&dpr=2#lkt=LocalPoiReviews"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ total }} reviews
        </a>
      </p>
      <div class="flex relative h-15 w-max gap-2">
        <div
          v-for="i in 5"
          :key="i"
          class="relative"
        >
          <img
            src="/img/star-outline.svg"
            class="w-15 shrink-0"
            width="15"
            height="15"
            alt=""
          >
          <img
            src="/img/star.svg"
            class="w-15 absolute top-0"
            :style="{ clipPath: getStarClipPath(i) }"
            width="15"
            height="15"
            alt=""
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    extended: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rating: process.env.NODE_ENV === 'development' ? 4.5 : 0,
      total: process.env.NODE_ENV === 'development' ? 23 : 0,
    };
  },
  async mounted() {
    if (process.env.NODE_ENV === 'development') return;
    const response = await fetch('/.netlify/functions/fetch-google-rating');
    const data = await response.json();
    this.rating = data.rating;
    this.total = data.ratings_total;
  },
  methods: {
    /**
     * Get the clip path for the star
     *
     * @param {number} position
     *
     * @returns {string} clip-path value
     */
    getStarClipPath(position) {
      const fullStars = Math.floor(this.rating);
      const decimal = this.rating - fullStars;

      if (position <= fullStars) {
        return 'inset(0)';
      } if (position === fullStars + 1) {
        const percentage = decimal * 100;
        return `inset(0 ${100 - percentage}% 0 0)`;
      }
      return 'inset(0 100% 0 0)';
    },
  },
});
</script>
