export default () => {
  /**
   * Format currency.
   *
   * @param   {Number} number
   * @param   {Boolean} withSign
   *
   * @returns  {String}
   */
  const formatCurrency = (number, withSign = false) => {
    const hasDecimals = number % 1 !== 0;
    const formatter = new Intl.NumberFormat('nl', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: hasDecimals ? 2 : 0,
      maximumFractionDigits: 2,
    });

    let formatted;
    if (withSign) {
      formatted = formatter.format(number);
    } else {
      formatted = formatter.formatToParts(number)
        .filter((p) => p.type !== 'currency')
        .reduce((s, p) => s + p.value, '')
        .trim();
    }

    return formatted + (hasDecimals ? '' : ',00');
  };

  return { formatCurrency };
};
