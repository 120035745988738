<template>
  <SiteHeader />
  <router-view />
  <WhatsApp />
  <SiteFooter />
</template>

<script>
import { defineComponent } from 'vue';
import SiteHeader from '@/components/Header.vue';
import SiteFooter from '@/components/Footer.vue';
import WhatsApp from '@/components/WhatsApp.vue';

export default defineComponent({
  components: {
    SiteHeader,
    SiteFooter,
    WhatsApp,
  },
});
</script>
