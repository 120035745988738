<template>
  <section class="pt-100 lg:pt-150">
    <div class="container">
      <div class="w-full xl:w-4/5 mx-auto">
        <div class="bg-gradient-to-br from-primary-400 to-primary-500 p-25 lg:p-50 rounded-3xl">
          <slot name="title" />
          <template v-if="!contactFormSuccess">
            <p
              v-if="text"
              class="text-gray-700 xl:w-1/2"
            >
              {{ text }}
            </p>
            <form
              class="mt-30 grid md:grid-cols-2 gap-x-[5rem]"
              @submit.prevent="onFormSubmit"
            >
              <input
                type="hidden"
                name="form-name"
                value="contact"
              >
              <div>
                <label
                  for="naam"
                  class="font-medium"
                >Jouw naam *</label>
                <input
                  id="naam"
                  type="text"
                  name="Naam"
                  required
                  class="block w-full bg-white rounded-lg p-10 mt-5 mb-25"
                  @change="$gtm.trackEvent({
                    event: 'form_start',
                    eventModel: {
                      form_id: 'demo',
                    },
                  })"
                />
              </div>
              <div>
                <label
                  for="email"
                  class="font-medium"
                >E-mailadres *</label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  required
                  class="block w-full bg-white rounded-lg p-10 mt-5 mb-25"
                />
              </div>
              <div>
                <label
                  for="clubName"
                  class="font-medium"
                >{{ clubLabel }} *</label>
                <input
                  id="clubName"
                  type="text"
                  name="Naam Businessclub/vereniging"
                  required
                  class="block w-full bg-white rounded-lg p-10 mt-5 mb-25"
                />
              </div>
              <div>
                <label
                  for="tel"
                  class="font-medium"
                >Telefoonnummer</label>
                <input
                  id="tel"
                  type="tel"
                  name="Telefoonnummer"
                  class="block w-full bg-white rounded-lg p-10 mt-5 mb-25"
                />
              </div>
              <div class="md:col-span-2">
                <label
                  for="bericht"
                  class="font-medium"
                >Jouw bericht *</label>
                <textarea
                  id="bericht"
                  name="Bericht"
                  required
                  class="block w-full bg-white rounded-lg p-10 mt-5 mb-25"
                />
              </div>
              <div>
                <button
                  type="submit"
                  class="inline-flex font-semibold px-20 lg:px-25 py-10 lg:py-15 rounded-full text-white bg-gradient-to-r from-gray-600 via-gray-900 to-gray-600 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
                >
                  Verstuur
                </button>
              </div>
            </form>
          </template>
          <div
            v-if="contactFormSuccess"
            class="rounded-md bg-green-500/20 text-green-700 px-15 py-10 mt-15"
          >
            Bedankt voor je bericht, we nemen contact met je op.
          </div>
          <div
            v-if="contactFormError"
            class="rounded-md bg-red-500/20 text-red-700 px-15 py-10 mt-15"
          >
            Er is iets misgegaan. Probeer het later nog eens.
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    text: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    clubLabel: {
      type: String,
      default: 'Jouw club',
    },
  },
  data() {
    return {
      contactFormSuccess: false,
      contactFormError: false,
    };
  },
  methods: {
    /**
     * Handle form submit
     *
     * @param {Event} event
     */
    async onFormSubmit(event) {
      const form = event.target;
      const formData = new FormData(form);
      const body = new URLSearchParams(formData).toString();

      try {
        await fetch('/', {
          method: 'POST',
          body,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });

        form.reset();

        this.$gtm.trackEvent({
          event: 'form_submit',
          eventModel: {
            form_id: 'demo',
          },
        });

        this.contactFormSuccess = true;
      } catch (error) {
        this.contactFormError = true;
      }
    },
  },
});
</script>
