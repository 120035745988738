<!-- eslint-disable max-len, vue/max-len -->
<template>
  <section
    id="home"
    class="pt-100 md:pt-150"
  >
    <div class="absolute top-0 right-0 mt-100 lg:mt-150 w-full md:w-1/2 max-w-4xl">
      <div class="w-full pt-[100%] bg-gradient-to-r from-primary-400 to-primary-500 rounded-full ml-[20%]" />
      <img
        src="/img/hero-paramedie.webp"
        alt="Paramedie software Clubee"
        title="Paramedie software Clubee"
        class="absolute top-0 right-0 mt-50 pl-25 md:-ml-50 md:pl-0"
      >
    </div>
    <div class="container relative mt-[110%] md:mt-0 xl:mt-50 2xl:mt-100">
      <div class="w-full md:w-1/2 max-w-4xl">
        <h1 class="text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold mb-25">
          Samenwerken als <span class="marker marker-lg">paramedische</span> regio
        </h1>
        <h2 class="text-xl md:text-2xl lg:text-3xl xl:text-4xl font-semibold">
          Website, app en leden-administratie in één pakket
        </h2>
        <p class="text-gray-500 text-lg lg:text-xl mt-25 sm:max-w-xs lg:max-w-xl">
          Clubee is de alles-in-één oplossing speciaal voor paramedische beroepsgroepen die meer willen gaan samenwerken. Met ledenadministratie, een website en een app wordt dit makkelijker dan ooit. Je website en app zijn geheel in eigen huisstijl en gekoppeld aan elkaar. Online in een mum van tijd omdat wij alles al hebben bedacht voor je! Probeer Clubee en vraag direct een gratis demo aan.
        </p>

        <div class="flex flex-col items-start">
          <router-link
            to="/demo-aanvragen"
            class="inline-flex mt-40 font-semibold px-20 lg:px-25 py-10 lg:py-15 rounded-full bg-gradient-to-r from-primary-400 via-primary-500 to-primary-400 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
          >
            Demo aanvragen
          </router-link>

          <router-link
            :to="{
              path: '/',
              hash: '#club',
            }"
            class="inline-flex items-center mt-25 font-semibold group"
          >
            <span class="h-50 w-50 mr-10 bg-black bg-opacity-5 group-hover:bg-opacity-10 transition rounded-full flex items-center justify-center">
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                xmlns="http://www.w3.org/2000/svg"
              ><path
                d="M13.707 8.293a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 1 1 1.414-1.414L6 12.586V1a1 1 0 0 1 2 0v11.586l4.293-4.293a1 1 0 0 1 1.414 0z"
                fill="#000"
                fill-rule="evenodd"
              /></svg>
            </span>
            Ontdek Clubee
          </router-link>
        </div>
      </div>
    </div>
  </section>

  <section class="pt-100 lg:pt-150 md:mt-100 2xl:mt-150">
    <div class="container">
      <div class="rounded-3xl bg-gray-100 p-25 md:p-50 xl:p-100">
        <div class="flex flex-wrap justify-between">
          <div class="w-full lg:w-4/5">
            <h2 class="text-3xl md:text-4xl xl:text-5xl font-bold">
              <span class="marker">Alles in één</span> voor de paramedische organisaties
            </h2>
            <p class="text-gray-500 mt-15">
              Het landelijke programma Organisatiegraad Paramedische Zorg heeft als doel de samenwerking tussen verschillende paramedische beroepsgroepen in de regio te bevorderen. Speciaal voor deze paramedische beroepsgroepen hebben we Clubee op maat doorintwikkelt om in te spelen op wensen vanuit beroepsgroepen zoals fysiotherapeuten, dietisten, logopedisten etcetera.
            </p>
            <router-link
              to="/demo-aanvragen"
              class="inline-flex mt-40 font-semibold px-20 lg:px-25 py-10 lg:py-15 rounded-full bg-gradient-to-r from-primary-400 via-primary-500 to-primary-400 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
            >
              Demo aanvragen
            </router-link>
          </div>

          <div class="grid md:grid-cols-2 flex-wrap items-start gap-25 mt-50">
            <div class="col-span-1 h-full bg-white text-gray-500 text-sm rounded-2xl p-40">
              <h4 class="text-lg font-medium mb-5 text-black">
                Website in eigen huisstijl
              </h4>
              <p>
                Een website voor je paramedische organisatie geheel in jouw huisstijl. Met kleuren, logo's en lettertypen naar keuze zorg je voor een duidelijke identiteit.
              </p>
            </div>
            <div class="col-span-1 h-full bg-white text-gray-500 text-sm rounded-2xl p-40">
              <h4 class="text-lg font-medium mb-5 text-black">
                App in eigen huisstijl
              </h4>
              <p>
                Met de app hebben je leden altijd toegang tot hun omgeving. Bekijk bijeenkomsten en meld je eenvoudig onlibe aan.
              </p>
            </div>
          </div>
          <div class="grid md:grid-cols-3 flex-wrap items-start gap-25 mt-25">
            <div class="col-span-1 h-full bg-white text-gray-500 text-sm rounded-2xl p-40">
              <h4 class="text-lg font-medium mb-5 text-black">
                Ledenadministratie
              </h4>
              <p>
                Met de geïntegreerde ledenadministratie is beheer een fluitje van een cent. Geen gedoe meer in excel, maar een simpele uitdraai van al jouw leden inclusief registratie van o.a. BIG, AGB En aangesloten netwerken.
              </p>
            </div>
            <div class="col-span-1 h-full bg-white text-gray-500 text-sm rounded-2xl p-40">
              <h4 class="text-lg font-medium mb-5 text-black">
                Koppeling met een overkoepelende regio website
              </h4>
              <p>
                Regionaal kunnen we hier alle vraag en aanbod bij elkaar laten komen voor de verschillende multidisciplinaire teams
              </p>
            </div>
            <div class="col-span-1 h-full bg-white text-gray-500 text-sm rounded-2xl p-40">
              <h4 class="text-lg font-medium mb-5 text-black">
                Voor clienten en verwijzers
              </h4>
              <p>
                Clienten en verwijzers vinden eenvoudig de aangeboden programma’s en waar deze te volgende zijn.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="pt-100 lg:pt-150">
    <div class="container">
      <div class="grid md:grid-cols-12 gap-50">
        <div class="col-span-full md:col-span-8">
          <blockquote class="relative pl-40 before:absolute before:inset-y-0 before:left-0 before:bg-gradient-to-b before:from-primary-400 before:to-primary-500 before:w-5">
            <span class="block text-2xl 2xl:text-3xl font-bold italic">
              “Met het alles-in-één platform van Clubee hebben we een oplossing geleverd die het voor TFO eenvoudig maakt om informatie te delen, leden te verbinden en hun communicatie te stroomlijnen. Dankzij onze geïntegreerde functies kunnen wij ons volledig richten op onze missie: het verbeteren van de fysiotherapeutische zorg in Twente.”
            </span>
            <cite class="block mt-25 not-italic text-gray-500 text-lg xl:text-xl 2xl:text-2xl">-Marleen Meijer, Twentse Organisatie Paramedie</cite>
          </blockquote>
        </div>
        <figure class="col-span-full md:col-span-4 md:col-start-9">
          <img
            src="/img/marleen.webp"
            alt="Marleen Meijer, Twentse Organisatie Paramedie"
            title="Marleen Meijer, Twentse Organisatie Paramedie"
            class="rounded-3xl"
          >
        </figure>
      </div>
    </div>
  </section>

  <section class="pt-100 lg:pt-150">
    <div class="container">
      <div class="w-full xl:w-4/5 mx-auto">
        <div class="flex flex-wrap justify-between items-end">
          <div class="w-full md:w-2/3 md:pr-25">
            <h2 class="text-3xl md:text-4xl xl:text-5xl font-bold">
              <span class="marker">Voorbeelden</span>
              van <br>andere regio's
            </h2>
            <p class="text-lg text-gray-500 mt-20 md:max-w-xl">
              Een overkoepelende website voor de paramedische zorg in je regio, gekoppelde aan de Clubee webite van de paramedische zorgaanbieders die in de regio werkzaam zijn. Ook de onderliggende zorgaanbieders hebben allemaal een eigen website via Clubee, zoals de fysiotherapie, ergotherapie en logopedie etc..
            </p>
          </div>
          <div class="flex flex-col md:flex-row gap-20 mt-50">
            <div class="flex-1 bg-gray-100 rounded-3xl p-10 overflow-hidden flex flex-col justify-between">
              <img
                class="ml-auto -mr-10"
                src="/img/case-top.webp"
                alt="TOP Paramedie website"
                title="TOP Paramedie website"
              >
              <a
                href="https://www.top-paramedie.nl/"
                target="_blank"
                rel="noopener noreferrer"
                class="relative block bg-white rounded-2xl p-25 lg:p-40 group hover:no-underline hover:bg-gray-200 transition"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-20 h-20 absolute top-0 right-0 m-25 lg:m-40"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                  />
                </svg>
                <h5 class="text-xl font-bold mb-10 group-hover:underline">
                  Twentse Organisatie Paramdie
                </h5>
                <p class="text-gray-500">
                  De TOP zocht naar een makkelijke manier om nieuws en informatie met haar leden te delen. Op hun eigen website en app delen ze nu nieuws, updaten ze leden live en zien ze direct wie zich opgeven. Zo voldoen ze direct aan de eisen van [van wie moesten ze dit?]
                </p>
              </a>
            </div>
            <div class="flex-1 bg-gray-100 rounded-3xl p-10 overflow-hidden flex flex-col justify-between">
              <a
                href="https://www.tfo-ua.nl/"
                target="_blank"
                rel="noopener noreferrer"
                class="relative block bg-white rounded-2xl p-25 lg:p-40 group hover:no-underline hover:bg-gray-200 transition"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-20 h-20 absolute top-0 right-0 m-25 lg:m-40"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                  />
                </svg>
                <h5 class="text-xl font-bold mb-10 group-hover:underline">
                  Twentse Fysiotherapeuten Organisatie
                </h5>
                <p class="text-gray-500">
                  Ook de organisaties aangesloten bij de TOP hebben nu een eigen website die ze makkelijk kunnen beheren.
                </p>
              </a>
              <img
                src="/img/case-tfo.webp"
                alt="TFO app"
                title="TFO app"
                class="-mr-10 ml-auto -mb-10"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="pt-100 lg:pt-150">
    <div class="container">
      <div class="grid grid-cols-2 gap-y-20 gap-x-30 md:grid-cols-4 lg:grid-cols-8 xl:gap-40">
        <img
          src="/img/logo-top.webp"
          alt="TOP logo"
          title="TOP logo"
        >
        <img
          src="/img/logo-tfo.webp"
          alt="TFO logo"
          title="TFO logo"
        >
        <img
          src="/img/logo-dietist.webp"
          alt="Dietist in Twente logo"
          title="Dietist in Twente logo"
        >
        <img
          src="/img/logo-leefstijlcoaches.webp"
          alt="Leefstijlcoaches Twente logo"
          title="Leefstijlcoaches Twente logo"
        >
        <img
          src="/img/logo-tvot.webp"
          alt="TVOT logo"
          title="TVOT logo"
        >
        <img
          src="/img/logo-paramedie.webp"
          alt="Paramedie Achterhoek logo"
          title="Paramedie Achterhoek logo"
        >
        <img
          src="/img/logo-toe.webp"
          alt="TOE logo"
          title="TOE logo"
        >
        <img
          src="/img/logo-lint.webp"
          alt="LINT logo"
          title="LINT logo"
        >
      </div>
    </div>
  </section>

  <section class="pt-100 lg:pt-150">
    <div class="container">
      <div class="w-full xl:w-4/5 mx-auto rounded-3xl bg-gray-100 p-25 grid md:grid-cols-12 gap-20 md:gap-50 md:p-50 xl:p-100">
        <div class="col-span-6">
          <h2 class="text-4xl lg:text-5xl font-bold">
            De <span class="marker marker-lg">voordelen</span> voor jouw regio
          </h2>
          <p class="text-gray-500 text-lg mt-15">
            Alle websites van de monodisciplinaire teams zijn gekoppeld aan de overkoepelende regio-website zodat programma's centraal worden ingegeven en beheerd en op beide sites automatisch worden gepubliceerd.
            <br><br>
            Speciaal voor paramedische organisaties zoals jullie biedt Clubee deze oplossing. Daarmee voldoe je in één keer aan de eisen rondom communicatie en heb je een eigen overkoepelende website die goed aansluit op je branche.
          </p>
          <router-link
            to="/demo-aanvragen"
            class="inline-flex mt-40 font-semibold px-20 lg:px-25 py-10 lg:py-15 rounded-full bg-gradient-to-r from-primary-400 via-primary-500 to-primary-400 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
          >
            Demo aanvragen
          </router-link>
        </div>
        <div class="col-span-6">
          <div class="bg-white text-gray-500 text-sm rounded-2xl p-25 mt-50 ml-25 lg:ml-50">
            <h4 class="text-lg font-medium mb-5 text-black">
              Één aanspreekpunt
            </h4>
            <p>
              Voor al je aangesloten organisaties.
            </p>
          </div>

          <div class="bg-white text-gray-500 text-sm rounded-2xl p-25 mt-40 mr-25 lg:mr-50">
            <h4 class="text-lg font-medium mb-5 text-black">
              Deel makkelijk programma's
            </h4>
            <p>
              En werk je website snel bij.
            </p>
          </div>

          <div class="bg-white text-gray-500 text-sm rounded-2xl p-25 mt-40 ml-25 lg:ml-40 lg:mr-10">
            <h4 class="text-lg font-medium mb-5 text-black">
              Eenvoudig ledenbeheer
            </h4>
            <p>
              Inclusief website en app.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <contact-form-section
    id="contact"
    name="Businessclubs"
    text="Met de gratis demo ervaar je zelf hoe ledenadministratie, een website én app voor jouw beroepsgroep werkt. Ervaar het gemak van Clubee en vraag direct een gratis demo aan."
  >
    <template #title>
      <h2 class="text-3xl xl:text-4xl font-bold mb-10">
        Vraag nu een <span class="marker marker-white">gratis demo</span> aan
      </h2>
    </template>
  </contact-form-section>

  <section class="pt-100 lg:pt-150">
    <div class="container">
      <div class="w-full xl:w-4/5 mx-auto grid md:grid-cols-12 gap-20 items-center md:gap-50">
        <div class="col-span-6">
          <h2 class="text-3xl md:text-4xl font-bold mb-25">
            Meer informatie over een regionale overkoepelende website?
          </h2>
          <p class="xl:text-xl text-gray-500 mb-25">
            Direct weten wat de kosten voor een website, app en ledenomgeving in 1 zijn? Speciaal voor de paramedie hebben wij een extra voordelig tarief. Neem contact op voor meer informatie.
          </p>
          <router-link
            :to="'/contact'"
            class="inline-flex font-semibold px-20 lg:px-25 py-10 lg:py-15 rounded-full text-white bg-gradient-to-r from-gray-600 via-gray-900 to-gray-600 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
          >
            Neem contact op
          </router-link>
        </div>
        <div class="col-span-6">
          <img
            src="/img/case-top.webp"
            alt="TOP Paramedie website"
            title="TOP Paramedie website"
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import ContactFormSection from '@/components/ContactFormSection.vue';

export default defineComponent({
  components: {
    ContactFormSection,
  },
});
</script>
